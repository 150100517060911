import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import { FiHeadphones, FiMail, FiMapPin, FiSmartphone } from "react-icons/fi";
import { Link } from 'react-router-dom';

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Welcome to my Programming World',
        description: '',
        buttonText: 'Download CV',
        buttonLink: 'https://docs.google.com/document/d/1izvkLiweRd2QFTI7INjEmAKsfnYbp47-WR-Clmk91Pg/edit?usp=sharing'
    }
]
const PortfolioLanding = () => {
    let title = 'About Me',
        description = `I am a quick learner who remains adaptable to keep up with technological advancements. I am enthusiastic about creating new, captivating user interfaces and constantly challenge myself to discover improved and more efficient coding methods. Currently, my focus lies in developing mobile applications specifically for Apple iOS devices such as the iPhone, iPad, Watch, and iPod. I am proficient in Objective-C, Swift, SwiftUI, and the XCode IDE.`;
    const PostList = BlogContent.slice(0, 3);
    return (
        <div className="active-dark bg_color--9">
            <Helmet pageTitle="Zain Haider" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />

            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title">I’m Zain Haider <br />
                                                <TextLoop>
                                                    <span> Senior IOS Developer.</span>
                                                    <span> Backend Developer.</span>
                                                    <span> FullStack Developer.</span>
                                                    <span> Team Lead.</span>
                                                </TextLoop>{" "}
                                            </h1>
                                            <h2>based in Pakistan.</h2>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn mt--30"><a className="btn-default btn-border btn-opacity" target="_blank" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--100  bg_color--8">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-8.jpg" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span className="subtitle">Senior Developer With 6+ Years of Experience</span>
                                            <h2 className="title mb--20">{title}</h2>
                                            <p className="description mt_dec--20">{description}</p>
                                        </div>
                                        <div className="row mt--40">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End About Area */}

            {/* Start CounterUp Area */}
            <div className="rn-counterup-area ptb--40 bg_color--8">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">My Accomplishments</span>
                                <h2 className="title">My Career Overview</h2>
                                <p className="description">I have grown strength over the past 6 years.</p>
                            </div>
                        </div>
                    </div>
                    <CounterOne />
                </div>
            </div>
            {/* End CounterUp Area */}

            {/* Start Service Area  */}
            <div id="service" className="fix">
                <div className="service-area creative-service-wrapper pb--120 bg_color--8" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mt--20 mb--30 mb_sm--0">
                                    <span className="subtitle">My technical stack</span>
                                    <h2 className="title">My Expertise</h2>
                                    <p className="description">I have extensive experience of following skills.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="50" column="col-lg-3 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area  */}

            {/* Start Testimonial Area */}
            <div className="rn-testimonial-area bg_color--9 ptb--100">
                <div className="container">
                    <Testimonial />
                </div>
            </div>
            {/* End Testimonial Area */}

            {/* Start Portfolio Area */}
            <div id="portfolio" className="fix">
                <div className="portfolio-area pb--120 bg_color--8">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mt--30 mb--30 mb_sm--0">
                                        <span className="subtitle">My Completed Projects</span>
                                        <h2 className="title">Selected Accomplishments</h2>
                                        <p className="description">Give a look at my live projects.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="18" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}


            {/* Start Contact Top Area  */}
            <div className="rn-contact-top-area ptb--60 bg_color--9">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title mb--30 text-center">
                                <span className="subtitle">Let's Say Hi</span>
                                <h2 className="title">Contact Me</h2>
                                <p className="description">Let's Build Something Together.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        {/* Start Single Address  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiHeadphones />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Contact Phone Number</h4>
                                    <p><a href="tel:+44 7355 967093">+44 7355 967093</a></p>
                                    <p><a href="tel:+92 300 4601345">+92 300 4601345</a></p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                        {/* Start Single Address  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiMail />
                                </div>
                                <div className="inner">
                                    <h4 className="title">Email Address</h4>
                                    <p><a href="mailto:zainpk121@icloud.com">zainpk121@icloud.com</a></p>
                                    <p><a href="mailto:contact@developerZain.com">contact@developerZain.com</a></p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                        {/* Start Single Address  */}
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="rn-address">
                                <div className="icon">
                                    <FiSmartphone />
                                </div>
                                <div className="inner">
                                    <h4 className="title">WhatsApp</h4>
                                    <p><a target="_blank" href="https://wa.me/+447355967093">+44 7355 967093</a></p>
                                    <p><a target="_blank" href="https://wa.me/+923004601345">+92 300 4601345</a></p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Address  */}

                    </div>
                </div>
            </div>
            {/* End Contact Top Area  */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

        </div>
    )
}

export default PortfolioLanding;
