import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ProgressBar } from 'react-bootstrap';

class TabsTwo extends Component {
    render() {
        let
            tab1 = "Main skills",
            tab2 = "Experience",
            tab3 = "Awards",
            tab4 = "Education";
        const { tabStyle } = this.props
        return (
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <div className={`rn-progress-bar progress-bar--1 mt_dec--10`}>
                                                <div className="single-progress">
                                                    <h6 className="title">Swift</h6>
                                                    <ProgressBar now={100} />
                                                    <span className="label">100%</span>
                                                </div>
                                                <div className="single-progress">
                                                    <h6 className="title">SwiftUI</h6>
                                                    <ProgressBar now={100} />
                                                    <span className="label">100%</span>
                                                </div>
                                                <div className="single-progress">
                                                    <h6 className="title">Objective-C</h6>
                                                    <ProgressBar now={80} />
                                                    <span className="label">80%</span>
                                                </div>
                                                <div className="single-progress">
                                                    <h6 className="title">Backend Development - NodeJS, Express, MongoDB</h6>
                                                    <ProgressBar now={80} />
                                                    <span className="label">80%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">Consultant Mobile Development Engineer<span> - Systems Limited</span></a> 2022 - Continue
                                                </li>
                                                <li>
                                                    <a href="/service">Sr. IOS Developer<span> - Purelogics</span></a> 2021 - 2022 (1Y 2M)
                                                </li>
                                                <li>
                                                    <a href="/service">Team Lead IOS & Android<span> - Gordian Solutions</span></a> 2017 - 2021 (3Y 8M)
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">Amal Career Fellowship <span>- Winner</span></a> 2018
                                                </li>
                                                <li>
                                                    <a href="/service">Lahore Science Exhibition <span>- Gold Medalist</span></a> 2019
                                                </li>
                                                <li>
                                                    <a href="/service">Swift Event Pakistan <span>- Winner</span></a> 2020
                                               </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">MS in Computer Science<span> - University of Central Punjab, Lahore, PK</span></a> 2021 - continue
                                                </li>
                                                <li>
                                                    <a href="/service">BS In Information Technology<span> - PUCIT, Lahore, PK</span></a> 2014 - 2018
                                               </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;