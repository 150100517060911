import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>I've worked with Zain Haider on various applications. I've found him a very hardworking and talented ios developer.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Mohsin Server </span> - CEO and Founder, Gordian Solutions.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>I've hired Zain Haider as an iOS Developer. His work on iOS projects is outstanding. Very talented resource that I'd highly recommend.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Hassan Rafique Awan </span> - Co-Founder, DevCrew I/O.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>I highly recommend Zain as an exceptional iOS Developer. Zain is a skilled professional with a deep understanding of iOS development, including expertise in Swift and Objective-C. His ability to deliver high-quality code and collaborate effectively with cross-functional teams is truly impressive. Zain is a valuable asset to any organization looking for a talented iOS Developer.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Yahia Mosaad </span> - iOS Technical Lead, Etisalat UAE.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Over the years, we have worked for same company as iOS Developer, which gave me the opportunity to see his leadership and teamwork skills firsthand.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Muhammad Awais </span> - Sr. Software Engineer, Techvista, LLC UAE.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                                <div className="col-lg-6 mt_md--40 mt_sm--40">
                                    <TabList className="testimonial-thumb-wrapper">
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/mohsin-server.png" alt="Testimonial Images" />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/hassan.png" alt="Testimonial Images" />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/yahia.png" alt="Testimonial Images" />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/awais.jpg" alt="Testimonial Images" />
                                                </div>
                                            </div>
                                        </Tab>
                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;