import React, { Component } from "react";
import { Link } from 'react-router-dom';
const ttfn = <img src="/assets/images/portfolio/ttfn.jpg" alt="React Creative Agency" />;
const Fidget_Widget = <img src="/assets/images/portfolio/fidget-widget.jpg" alt="React Creative Agency" />;
const Packet_Taxi = <img src="/assets/images/portfolio/paket-taxi.jpg" alt="React Creative Agency" />;
const Fetch_Taxi = <img src="/assets/images/portfolio/fetch-text.jpg" alt="React Creative Agency" />;
const Desygner = <img src="/assets/images/portfolio/desygner.jpg" alt="React Creative Agency" />;
const NFS = <img src="/assets/images/portfolio/nfs.jpg" alt="React Creative Agency" />;
const DogHood = <img src="/assets/images/portfolio/DogHood.png" alt="React Creative Agency" />;
const Kikiz_Korner = <img src="/assets/images/portfolio/kikizKorner.png" alt="React Creative Agency" />;
const Freeze = <img src="/assets/images/portfolio/Freeze.jpg" alt="React Creative Agency" />;
const Platers = <img src="/assets/images/portfolio/Platers.png" alt="React Creative Agency" />;
const myEtisalat = <img src="/assets/images/portfolio/my-etisalat.jpg" alt="React Creative Agency" />;
const Mod5r = <img src="/assets/images/portfolio/mod5r.jpg" alt="React Creative Agency" />;
const Skin_Scanner = <img src="/assets/images/portfolio/skin-scanner.jpg" alt="React Creative Agency" />;
const FIN = <img src="/assets/images/portfolio/FIN.jpg" alt="React Creative Agency" />;
const Uvertz = <img src="/assets/images/portfolio/Uvertz.jpg" alt="React Creative Agency" />;
const BeaconsPro = <img src="/assets/images/portfolio/BeaconsPro.png" alt="React Creative Agency" />;
const Alchemy = <img src="/assets/images/portfolio/Alchemy.png" alt="React Creative Agency" />;
const Whiplash = <img src="/assets/images/portfolio/Whiplash.png" alt="React Creative Agency" />;

const PortfolioListContent = [
    {
        image: myEtisalat,
        category: 'Application Development',
        title: 'My Etisalat',
        description: 'Fintech Application with 8M+ active users',
        url: 'https://apps.apple.com/us/app/my-etisalat/id558287646'
    },
    {
        image: Mod5r,
        category: 'Application Development',
        title: 'Mod5r',
        description: 'View transactions, manage accounts and make investments',
        url: 'https://apps.apple.com/pk/app/mod5r-%D9%85%D8%AF%D8%AE%D8%B1/id1555706109'
    },
    {
        image: Desygner,
        category: 'Application Development',
        title: 'Desygner',
        description: 'Photo and PDF Editor with 15M+ active users',
        url: 'https://apps.apple.com/pk/app/desygner-design-any-graphic/id1052684637'
    },
    {
        image: DogHood,
        category: 'Application Development',
        title: 'DogHood',
        description: 'Connect with the Dog Community',
        url: 'https://apps.apple.com/us/app/doghood-dog-care-platform/id1547568850'
    },
    {
        image: Skin_Scanner,
        category: 'Full Stack Development',
        title: 'Skin Scanner',
        description: 'Scan, diganose, consult specialists and order mediciens',
        url: 'https://apps.apple.com/pk/app/skin-care-skin-scanner/id6446048837'
    },
    {
        image: Packet_Taxi,
        category: 'Application Development',
        title: 'Paket Taxi User',
        description: 'Package delivery service with realtime updates',
        url: 'https://apps.apple.com/us/app/id1547988016'
    },
    {
        image: Packet_Taxi,
        category: 'Application Development',
        title: 'Paket Taxi Driver',
        description: 'Package delivery service with realtime updates',
        url: 'https://apps.apple.com/pk/app/paket-taxi-driver/id1507407191'
    },
    {
        image: FIN,
        category: 'Application Development',
        title: 'FIN MAN',
        description: 'IoT application for vahicle tracking and configuration',
        url: 'https://apps.apple.com/us/app/fin-man/id1612207033'
    },
    {
        image: Freeze,
        category: 'Application Development',
        title: 'Freeze',
        description: 'Online debt solution management and advise',
        url: 'https://itunes.apple.com/gb/app/freeze/id1452264470?mt=8'
    },
    {
        image: Uvertz,
        category: 'Application Development',
        title: 'UVertz',
        description: 'Iot application for location based advertising',
        url: 'https://apps.apple.com/us/app/uvertz-driver/id1455439763'
    },
    {
        image: BeaconsPro,
        category: 'Application Development',
        title: 'Beacons Pro',
        description: 'IoT app with iBeacon protocols for ranging and monitoring',
        url: 'https://apps.apple.com/us/app/beacons-pro/id1587012836?platform=iphone'
    },
    {
        image: Kikiz_Korner,
        category: 'Backend Development',
        title: 'Kikiz Korner',
        description: 'Online Jewellery store with different user types',
        url: 'https://play.google.com/store/apps/details?id=com.wappinc.kikizkorner'
    },
    {
        image: ttfn,
        category: 'Application Development',
        title: 'TTFN',
        description: 'Create connections by scanning QA code or with deep linking',
        url: 'https://apps.apple.com/us/app/ttfn/id1558659483'
    },
    {
        image: Fetch_Taxi,
        category: 'Application Development',
        title: 'Fetch Taxi',
        description: 'Ride booking application with realtime ride updates',
        url: 'https://apps.apple.com/us/app/fetch-taxi-driver-app/id1459561484'
    },
    {
        image: Alchemy,
        category: 'Application Development',
        title: 'Alchemy Crypto',
        description: 'Companion app for crypo wallet holders',
        url: 'https://apps.apple.com/pk/app/alchemy-crypto/id1633310308'
    },
    {
        image: Whiplash,
        category: 'Application Development',
        title: 'Whiplash',
        description: 'Saloon managment application for multiple user types',
        url: 'https://apps.apple.com/mx/app/whip-lash-admin/id1435527488?platform=iphone'
    },
    {
        image: Fidget_Widget,
        category: 'Application Development',
        title: 'Fidget Widget',
        description: 'Simple game with millions of impressions on app store',
        url: 'https://apps.apple.com/us/app/fidget-widget-keep-scrolling/id1456233150'
    },
    {
        image: NFS,
        category: 'Application Development',
        title: 'NFS',
        description: 'Deliver packages around the world within 24 hours',
        url: 'https://www.nfs-solutions.net'
    },
    {
        image: Platers,
        category: 'Full Stack Development',
        title: 'Platers - Food',
        description: 'Buy and sell food. Support online payments',
        url: 'https://apps.apple.com/us/app/platers-homemade-food/id1550524010'
    },
]

class PortfolioList extends Component {
    render() {
        const { column, styevariation } = this.props;
        const list = PortfolioListContent.slice(0, this.props.item);
        return (
            <React.Fragment>
                {list.map((value, index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`im_portfolio ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to="/portfolio-details">
                                        {value.image}
                                    </Link>
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to="/portfolio-details">{value.category}</Link>
                                        </div>
                                        <h4 className="title"><Link to="/portfolio-details">{value.title}</Link></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            <a className="transparent_link" target="_blank" href={value.url}></a>
                        </div>
                    </div>
                ))}

            </React.Fragment>
        )
    }
}
export default PortfolioList;