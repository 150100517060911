import React, { Component } from "react";
// import { FiServer, FiLayers, FiUsers, FiMonitor, FiActivity, FiWatch } from "react-icons/fi";

const ServiceList = [
    {
        // icon: <FiMonitor />,
        title: 'Swift',
        // description: ''
    },
    {
        title: 'SwiftUI',
    },
    {
        title: 'Objective-C',
    },
    {
        title: 'GIT',
    },
    {
        title: 'Automated Testing (Unit testing, UI testing)',
    },
    {
        title: 'Automation (Appcentre, XCode Cloud)',
    },
    {
        title: 'Archtectures (MVC, MVVM, VIPER, CLEAN)',
    },
    {
        title: 'Design Patterns',
    },
    {
        title: 'SOLID Principles',
    },
    {
        title: 'Reactive Programing (Combine, RxSwift)',
    },
    {
        title: 'GraphQL',
    },
    {
        title: 'Local storage (Core Data, Realm, UserDefaults, FileManager)',
    },
    {
        title: 'Firebase',
    },
    {
        title: 'Rest APIs',
    },
    {
        title: 'Sockets',
    },
    {
        title: 'IoT (BLE, iBeacons)',
    },
    {
        title: 'Augmented Reality',
    },
    {
        title: 'Machine Learning (Object tracking and manipulation)',
    },
    {
        title: 'In app purchases (Apple, Third Party)',
    },
    {
        title: 'Maps (Google, Apple, Mapbox)',
    },
    {
        title: 'Networking (URL session, Moya, Alamofire)',
    },
    {
        title: 'App Clips',
    },
    {
        title: 'App Extensions',
    },
    {
        title: 'VOIP (Audio/Video calling)',
    },
    {
        title: 'Fintech (SSL Pinning)',
    },
    {
        title: 'Motion sensors (Apple Watch, iPhone)',
    },
    {
        title: 'Health kit',
    },
    {
        title: 'Push notifications',
    },
    {
        title: 'Deep linking',
    },
    {
        title: 'Keychain APIs',
    },
    {
        title: 'Associated Domains',
    },
    {
        title: 'Testing (Testflight, Appcentre, Firebase, Diawi)',
    },
    {
        title: 'Appstore submissions',
    },
    {
        title: 'NodeJS',
    },
    {
        title: 'MongoDB',
    },
    {
        title: 'Express',
    },
    {
        title: 'AWS (EC2, DynamoDB, S3 Bucket)',
    },
]

class ServiceThree extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = ServiceList.slice(0, this.props.item);
        return (
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            {/* <a href="/service-details"> */}
                            <div className="service service__style--2 text-left bg-gray">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h3 className="title">{val.title}</h3>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                            {/* </a> */}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
